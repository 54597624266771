/*
Author:      Zachary Thomas
Created:     10/11/2022
Modified:    10/11/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.printer-safe-estimate-page {
  display: none;

  @media print {
    display: block;
  }
}