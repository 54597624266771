/*
Author:      Zachary Thomas
Created:     10/6/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.delay-event-modal-header {
  color: var(--text-active);
  background-color: var(--operations-primary);
}