/*
Author:      Dimitra Weinstein
Created:     9/29/2022
Modified:    10/11/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.estimate-calculation-page {
  display: block;

  @media print {
    display: none;
  }
}

.save-query-button.btn {
  display: inline-block;
  color: var(--title);
  background-color: var(--border-dark);
  border-color: var(--border-dark);
}

.save-query-button.btn:hover {
  border-color: var(--text-inactive);
}

.estimate-results {
  background: #124a92;
  background: -webkit-linear-gradient(to right, #124a92, #187795);
  background: linear-gradient(to right, #124a92, #187795);
}
