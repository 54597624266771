/*
Author:      Dimitra Weinstein
Created:     9/28/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.delay-event-card {
  border-width: 0px !important;
  background-color: var(--background-near) !important;
}

.delay-title-text {
  color: var(--title);
  font-size: 1.4rem;
}

.delay-event-header {
  background-color: var(--operations-primary) !important;
}

.no-events-text {
  font-size: 1.5rem;
}