/*
Author:      Dimitra Weinstein
Created:     8/26/2022
Modified:    9/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.production-schedule-body {
  background-color: var(--background-close);
}

.modal-button {
  display: block;
  width: 250px;
  position: absolute;
  margin-left: 75%;
  z-index: 1000;
}

.timephase-table {
  overflow-x: scroll;
}

.production-schedule {
  background: #124A92;
  background: -webkit-linear-gradient(to right, #124A92, #187795);
  background: linear-gradient(to right, #124A92, #187795);
}

.no-timephase-text {
  font-size: 1.5rem;
}