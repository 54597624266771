/*
Author:      Zachary Thomas
Created:     8/6/2021
Modified:    8/6/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.info-container {
  display: inline-block !important;
}

.info-button {
  box-shadow:none !important;
  outline:0px !important;
}

.fa-info-circle {
  color: var(--primary-background);
}