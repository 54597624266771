/*
Author:      Zachary Thomas
Created:     10/20/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.survey-page {
  display: block;
  background-color: var(--background-close);

  @media print {
    display: none;
  }

  .estimate-results-survey {
    background: #124a92;
    background: -webkit-linear-gradient(to right, #124a92, #187795);
    background: linear-gradient(to right, #124a92, #187795);
  }
}
