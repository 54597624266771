/*
Author:      Grayson Fleming
Created:     6/21/2021
Modified:    10/11/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.navbar-custom {
  display: flex;

  @media print {
    display: none !important;
  }
}

.navbar-brand {
  margin: 0 1rem;
  font-size: 1.5rem !important;
  font-weight: bold;
  color: var(--title);
  visibility: visible;
}

.navbar-brand:hover {
  color: var(--title);
}

.navbar-basic {
  background: #124A92;
  background: -webkit-linear-gradient(to right, #124A92, #187795);
  background: linear-gradient(to right, #124A92, #187795);
}

.navbar-operations {
  background: var(--operations-primary);
}

.navbar-admin {
  background: var(--admin-primary);
}