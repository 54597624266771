/*
Author:      Zachary Thomas
Created:     6/21/2021
Modified:    10/11/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.footer-custom {
  display: flex;

  @media print {
    display: none !important;
  }
}

.footer-basic {
  color: var(--text-active);
  background: #124A92;
  background: -webkit-linear-gradient(to right, #124A92, #187795);
  background: linear-gradient(to right, #124A92, #187795);
}

.footer-operations {
  color: var(--text-active);
  background-color: var(--operations-primary);
}

.footer-admin {
  color: var(--text-active);
  background-color: var(--admin-primary);
}