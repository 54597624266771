/*
Author:      Grayson Fleming
Created:     7/29/2021
Modified:    10/5/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

body {
  --admin-gradient-colors: #124A92, #187795, #38686A;
  --operations-gradient-colors: #187795, #38686A;
  --basic-user-gradient-colors: #124A92, #187795;
  --primary-background: #124a92;
  --page-title: #212121;
  --title: #fff;
  --admin-title: #f8d90f;
  --title-hover: #e5e5e5;
  --admin-title-hover: #e4d25d;
  --text-active: #fff;
  --text-inactive: #ffffffaa;
  --text-muted: #777777;
  --text-hover: #ffffffdd;
  --content-text: #141414;
  --muted-text: #212121;
  --tab-active: #1265b2;
  --tab-inactive: #0d6efd;
  --tab-hover: #3d8bfd;
  --background-dark: #b0b4b6;
  --background-far: #d8dadb;
  --background-near: #fafafa;
  --background-close: #fff;
  --background-hover: #f0f3f4;
  --link-background-hover: #e0e8f7;
  --selected-estimate: #99bcff;
  --top-lead-time: #bd0000;
  --border: #0000001f;
  --border-dark: #0000003f;
  --link: #0645AD;
  --very-muted-confirm: #15ca3210;
  --muted-confirm: #15ca3230;
  --confirm: #15ca3260;
  --chart-background: #ededed;
  --chart-background-alternative: #d5d5d5;
  --chart-text: #141414;
  --chart-column-supply: #99C2A2;
  --chart-column-demand: #C5EDAC;
  --chart-line-running-balance: #66C7F4;
  --severity-low: #4dd750;
  --severity-medium: #f5d372;
  --severity-high: #D7504D;
  --light-button-hover: #cbcfd2;
  --operations-primary: #125940;
  --admin-primary: #541b38;
  --dark-text-active: #000000;
  --dark-text-inactive: #000000aa;
  --dark-text-hover: #000000dd;
  --light-text: #fff;
  --light-text-muted: #cbcfd2;
}

.app {
  max-width: 90vw !important;
}

.lead-time-text {
  font-size: 2rem;
  margin: auto;
}

.lead-time-header {
  color: white !important;
  background-color: #124a92 !important;
}

html,
body {
  height: 100% !important;
  background-color: var(--background-far) !important;
}

main.dark-mode {
  background-color: var(--background-dark) !important;
}

main {
  flex: 1 0 auto;
  min-height: 100vh;
}

.selectable-container {
  color: var(--link);
}

.selectable-container:hover {
  cursor: pointer;
  text-decoration: underline;
  background-color: var(--link-background-hover);
}

.font-weight-bold {
  font-weight: 600 !important;
}

html,
body {
  height: 100% !important;
  background-color: #f0f3f4 !important;
}

main {
  flex: 1 0 auto;
  min-height: calc(100vh - 100px);
}

.container {
  max-width: 100vw;
  padding: 0;
}

.form-check-big {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.btn-light {
  border-width: 2px !important;
}

.btn-light:hover {
  background-color: var(--light-button-hover) !important;
  border-color: var(--light-button-hover) !important;
}