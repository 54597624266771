/*
Author:      Zachary Thomas
Created:     4/8/2021
Modified:    4/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.text-blurb {
  text-align: center;
}