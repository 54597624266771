/*
Author:      Dimitra Weinstein
Created:     9/29/2022
Modified:    9/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.estimate-history {
    background: #124A92;
    background: -webkit-linear-gradient(to right, #124A92,  #187795);
    background: linear-gradient(to right, #124A92, #187795);
}

.estimate-history-gradient-user {
    background: #124A92;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #2589BD);
    background: linear-gradient(to right, #124A92, #187795, #2589BD);
}

.estimate-history-gradient-operations {
    background: #81f69e;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #81f69e);
    background: linear-gradient(to right, #124A92, #187795, #81f69e);
}

.estimate-history-gradient-admin {
    background: #f8d90f;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #f8d90f);
    background: linear-gradient(to right, #124A92, #187795, #f8d90f);
}