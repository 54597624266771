/*
Author:      Zachary Thomas
Created:     6/21/2021
Modified:    6/21/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

$primary: #124a92;
$secondary: #6c757d;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";