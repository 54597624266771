/*
Author:      Zachary Thomas
Created:     10/6/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.severity-slider::-webkit-slider-thumb {
  background: var(--operations-primary);
}