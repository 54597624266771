/*
Author:      Zachary Thomas
Created:     10/6/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.part-list-row {
  background-color: var(--background-close);
  border-color: var(--border);
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  cursor: pointer;
}