/*
Author:      Zachary Thomas
Created:     4/1/2021
Modified:    4/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.page-404 {
  border-style: solid;
  border-width: 2px;
  border-color: var(--border);
  border-radius: 5px;
  background-color: var(--background-close);
  margin: 5rem;
  padding: 4rem 0;
  text-align: center;
}