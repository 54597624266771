/*
Author:      Zachary Thomas
Created:     10/7/2022
Modified:    10/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.estimate-history-row {
  cursor: pointer;
}

.estimate-history-row.selected {
  background-color: var(--selected-estimate);
}