/*
Author:      Grayson Fleming
Created:     7/30/2021
Modified:    10/6/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.global-config-page {
  max-width: 50%;
  border-width: 0px !important;
}

.config-form-body {
  background-color: var(--background-hover);
}

.save-config-button {
  max-width: 25%;
}

.configuration-header {
  background-color: var(--admin-primary) !important;
}