/*
Author:      Zachary Thomas
Created:     10/4/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.delay-event-row {
  cursor: pointer;
  user-select: none;
}

.severity-bar {
  width: 100%;
  height: 100%;
  color: var(--title);
  background-color: var(--severity-high);
}

.severity-bar.severity-low {
  background-color: var(--severity-low);
}

.severity-medium {
  background-color: var(--severity-medium);
}

.severity-high {
  background-color: var(--severity-high);
}