/*
Author:      Dimitra Weinstein
Created:     9/28/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.estimate-calculator {
    background: #124A92;
    background: -webkit-linear-gradient(to right, #124A92, #187795);
    background: linear-gradient(to right, #124A92, #187795);
}

.estimate-calculator-gradient-user {
    background: #124A92;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #2589BD);
    background: linear-gradient(to right, #124A92, #187795, #2589BD);
}

.estimate-calculator-gradient-operations {
    background: #81f69e;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #81f69e);
    background: linear-gradient(to right, #124A92, #187795, #81f69e);
}

.estimate-calculator-gradient-admin {
    background: #f8d90f;
    background: -webkit-linear-gradient(to right, #124A92, #187795, #f8d90f);
    background: linear-gradient(to right, #124A92, #187795, #f8d90f);
}