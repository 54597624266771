/*
Author:      Zachary Thomas
Created:     10/6/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.sorted-header-column {
  cursor: pointer;
}

.sorted-header-column:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sorted-header-selected {
  color: var(--content-text);
  user-select: none;
}

.light-text .sorted-header-selected {
  color: var(--light-text);
}

.sorted-header-unselected {
  color: var(--text-muted);
  user-select: none;
}

.light-text .sorted-header-unselected {
  color: var(--light-text-muted);
}
