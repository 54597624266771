/*
Author:      Zachary Thomas
Created:     6/8/2021
Modified:    9/28/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.login-header {
    background: #124A92;
    background: -webkit-linear-gradient(to right, #124A92,  #187795);
    background: linear-gradient(to right, #124A92, #187795);
  }

  .login-button.btn {
    color: var(--title);
    background: #124A92;
    /* background: -webkit-linear-gradient(to right, #124A92,  #187795);
    background: linear-gradient(to right, #124A92, #187795); */
    border-color: var(--border-dark);
  }
  
  .login-button.btn:hover {
    border-color: var(--text-inactive);
  }