.memo-text-area {
    overflow: auto;
    vertical-align: top;
}

.memo-container {
    vertical-align: top;
}

.empty-info-text {
    color: var(--text-muted);
    font-style: italic;
}